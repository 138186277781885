import LayoutContainer from 'components/Common/LayoutContainer'
import React from 'react'
import styled from 'styled-components'
import NavigationNavItems from './NavigationHeader/NavigationNavItems'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useIsHomepage from 'home/hooks/isHomepage'

const NavigationSubHeaderWrapper = styled.div`
  background: ${props => props.theme.palette.neutral.default.eight};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const SubHeaderBar = React.forwardRef<HTMLDivElement>((_, ref) => {
  const isHomepage = useIsHomepage()

  return <NavigationSubHeaderWrapper className="no-print" ref={ref}>
    {isHomepage && (
      <LayoutContainer>
        <NavigationNavItems />
      </LayoutContainer>
    )}
    {!isHomepage && (
      <CSSBreakpoint min="tablet">
        <LayoutContainer>
          <NavigationNavItems />
        </LayoutContainer>
      </CSSBreakpoint>
    )}
  </NavigationSubHeaderWrapper>
})

export default SubHeaderBar
