import React, { ComponentProps, useCallback, useContext } from 'react'
import BusinessTravellerAccountMenuContent from './BusinessTravellerAccountMenuContent'
import Modal from 'components/Luxkit/Modal/Modal'
import ModalContext from 'contexts/ModalContext'
import IconButton from 'components/Luxkit/Button/IconButton'
import { connect } from 'react-redux'
import { isLoggedIn } from 'selectors/accountSelectors'
import BusinessAccountModeIcon from './BusinessAccountModeIcon'
import { useAppDispatch } from 'hooks/reduxHooks'
import { accountAccessShowLogin } from 'actions/UiActions'

interface MappedStateProps {
  isLoggedIn?: boolean
}

type InheritedIconButtonProps = Pick<ComponentProps<typeof IconButton>, 'size' | 'className'>
interface Props extends MappedStateProps, InheritedIconButtonProps {
}

function BusinessTravellerAccountIconButtonMenu(props: Props) {
  const { isLoggedIn, ...rest } = props
  const dispatch = useAppDispatch()
  const triggerSignInFlow = useCallback(() => {
    dispatch(accountAccessShowLogin())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const showModal = useContext(ModalContext)
  const toggleModal = useCallback(() => {
    showModal(<Modal title="Your account">
      <BusinessTravellerAccountMenuContent />
    </Modal>)
  }, [showModal])

  return <IconButton
    {...rest}
    kind="primary"
    shape="circle"
    onClick={isLoggedIn ? toggleModal : triggerSignInFlow}
  >
    <BusinessAccountModeIcon />
  </IconButton>
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => {
  return {
    isLoggedIn: isLoggedIn(state),
  }
})(BusinessTravellerAccountIconButtonMenu)
