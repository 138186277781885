import { useCallback, useContext, useEffect } from 'react'
import { addToCheckoutCart } from 'actions/CheckoutActions'
import { addToCartGTM } from 'analytics/eventDefinitions'
import { fireEcommerceGTMEvent } from 'api/googleTagManager'
import { navigateToCheckout } from 'checkout/actions/navigateActions'
import { generateLuxPlusSubscriptionItems } from 'lib/checkout/luxPlusSubscription/cart'
import CurrencyContext from 'contexts/currencyContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchProductSubscription } from 'actions/LoyaltyActions'

function useRenewalAction() {
  const subscriptionProduct = useAppSelector(state => state.loyalty.products.subscription)
  const appDispatch = useAppDispatch()
  const currency = useContext(CurrencyContext)

  useEffect(() => {
    appDispatch(fetchProductSubscription())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useCallback(() => {
    const joinFeeFreeOffer = subscriptionProduct.offers?.find(offer => offer.joinFeeWaived)
    if (!joinFeeFreeOffer) return
    const cartItems = generateLuxPlusSubscriptionItems(joinFeeFreeOffer, true)
    fireEcommerceGTMEvent(addToCartGTM(joinFeeFreeOffer, currency, joinFeeFreeOffer?.price, undefined, 'subscription'))
    appDispatch(addToCheckoutCart(cartItems))
    appDispatch(navigateToCheckout())
  }, [appDispatch, currency, subscriptionProduct.offers])
}

export default useRenewalAction
