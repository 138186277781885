import cn from 'clsx'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { mediaQueryUp } from 'components/utils/breakpoint'
import config from 'constants/config'
import { useHeaderStateObserver } from 'contexts/HeaderStateContext'
import GeoContext from 'contexts/geoContext'
import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import AtolProtectedBanner from '../HeaderBanners/AtolProtectedBanner'
import CookiesDisabledWarningBanner from '../HeaderBanners/CookiesDisabledWarningBanner'
import NotificationBanner from '../HeaderBanners/NotificationBanner'
import NavigationTertiaryHeader from './NavigationHeader/NavigationTertiaryHeader'
import SubHeaderBar from './SubHeaderBar'
import StickyHeaderBar from './StickyHeaderBar'
import HeaderBar from './HeaderBar'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import TextLink from 'components/Luxkit/TextLink'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import LuxPlusWarningMessageBanner from 'luxPlus/components/LuxPlusWarningMessageBanner'
import { connect } from 'react-redux'
import ContactOptionsBanner from '../HeaderBanners/ContactOptionsBanner'
import LuxPlusRenewalBanner from 'luxPlus/components/LuxPlusRenewalBanner'
import { isPendingRenewalLuxPlusMember } from 'luxPlus/selectors/featureToggle'

const Root = styled.header`
  position: relative;
  background: ${props => props.theme.palette.neutral.default.eight};
  display: flex;
  flex-direction: column;
`

const AtolProtectedBannerWrapper = styled(AtolProtectedBanner)`
  ${mediaQueryUp.tablet} {
    display: none;
  }
`

interface MappedProps {
  subscription: App.MemberSubscription;
  isPendingRenewalLuxPlusMember: boolean;
}

interface Props extends MappedProps {
  className?: string
  notificationSlug?: string
  stickySearch?: boolean
}

function HeaderMain(props: Props) {
  const {
    className,
    notificationSlug,
    stickySearch,
    subscription,
    isPendingRenewalLuxPlusMember,
  } = props

  const rootRef = useRef<HTMLElement>(null)
  useHeaderStateObserver('site', rootRef)
  const { currentRegionCode } = useContext(GeoContext)
  const HAS_NAV_HEADER = config.businessTraveller.currentAccountMode !== 'business'
  const showSlimSearch = useSlimMobileSearch()

  return <>
    <TripGuard>
      {config.SHOW_CONTACT_BANNER && <CSSBreakpoint max="mobile">
        <ContactOptionsBanner/>
      </CSSBreakpoint>}
    </TripGuard>
    {subscription.error && <LuxPlusWarningMessageBanner />}
    {isPendingRenewalLuxPlusMember && <LuxPlusRenewalBanner />}
    <CSSBreakpoint min="tablet">
      <NavigationTertiaryHeader />
    </CSSBreakpoint>
    <Root ref={rootRef} className={cn('PageHeader', className)}>
      <TripGuard>
        {currentRegionCode === 'GB' && <AtolProtectedBannerWrapper />}
      </TripGuard>
      {config.agentHub.isEnabled && <PageBanner
        kind="promotional-primary"
        description={
          <>Need help? Email <TextLink href="mailto:agenthubsupport@luxuryescapes.com">agenthubsupport@luxuryescapes.com</TextLink></>
        }
      />}
      {config.notification && <NotificationBanner slug={notificationSlug} />}
      <CookiesDisabledWarningBanner />
    </Root>
    { stickySearch && <StickyHeaderBar /> }
    { !stickySearch && <HeaderBar /> }

    {HAS_NAV_HEADER && !showSlimSearch && <SubHeaderBar />}
  </>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  subscription: state.loyalty.member.subscription,
  isPendingRenewalLuxPlusMember: isPendingRenewalLuxPlusMember(state),
})

export default connect(mapStateToProps)(HeaderMain)
